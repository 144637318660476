<p-card>
    <div class="p-grid p-mt-2">
        <div class="p-col-12 p-field">
            <label for="reason_transaction_status_id">{{ 'transaction.entity_fields.reason_transaction_status_id' |
                translate}}</label>
            <p-dropdown name="reason_transaction_status_id" [options]="reasons" optionLabel="name" optionValue="id"
                [(ngModel)]="transaction.reason_transaction_status_id" placeholder="{{ 'general.select' | translate}}"
                required></p-dropdown>
        </div>
        <div class="p-col-12 p-field">
            <label for="transaction_state_notes">{{ 'transaction.entity_fields.transaction_state_notes' |
                translate}}</label>
            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="transaction.transaction_state_notes"></textarea>
            <!--input name="transaction_state_notes" type="text" pInputText
                [(ngModel)]="transaction.transaction_state_notes" required /-->
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-d-flex">
            <!--p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()" *ngIf="transaction.id!=null"></p-button-->
            <div class="flex-grow-1"></div>
            <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
                styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
            <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
                (click)="saveTransaction()"></p-button>
        </div>
    </ng-template>
</p-card>