import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CountriesService } from '../../countries/countries.service';
import { ReasonsTransactionsStatusService } from '../reasons-transactions-status.service';

@Component({
  selector: 'app-reasons-transactions-status-list',
  templateUrl: './reasons-transactions-status-list.component.html',
  styleUrls: ['./reasons-transactions-status-list.component.scss']
})
export class ReasonsTransactionsStatusListComponent {
  @ViewChild("grid") public grid: Table;
  public reasonsTransactionsStatusFilters: any = {};
  public reasonsTransactionsStatus: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;

  public first;
  public last;
  constructor(
    private reasonsTransactionsStatusService: ReasonsTransactionsStatusService,
    private router: Router,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //this.loadCountries();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.reasonsTransactionsStatusFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.reasonsTransactionsStatusFilters);
    this.reasonsTransactionsStatusService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.reasonsTransactionsStatus = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(reasonTransactionStatus) {
    this.router.navigate(['/configuration/reasonsTransactionsStatus/', reasonTransactionStatus.id]);
  }

  delete(reasonTransactionStatus) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reasonTransactionStatus.delete_message", { name: reasonTransactionStatus.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.reasonsTransactionsStatusService.delete(reasonTransactionStatus.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });

            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

}
