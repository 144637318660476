<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/reasonsTransactionsStatus']">{{ 'reasonTransactionStatus.entity.name_plural' |
            translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li [hidden]="reasonTransactionStatus.id==null"><a
            [routerLink]="['/configuration/reasonsTransactionsStatus', reasonTransactionStatus.id]">{{reasonTransactionStatus.name}}</a>
    </li>
    <li [hidden]="reasonTransactionStatus.id!=null">{{'component.reasonTransactionStatus.detail.new_entity' |
        translate}}</li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<form #formDetails="ngForm">
    <p-card header="{{'component.reasonTransactionStatus.detail.detail' | translate}}">
        <div class="p-grid">
            <div class="p-col-12 p-field">
                <label for="name">{{ 'reasonTransactionStatus.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="reasonTransactionStatus.name" required />
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex">
                <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash"
                    styleClass="p-button-danger" (click)="delete()" *ngIf="reasonTransactionStatus.id!=null"></p-button>
                <div class="flex-grow-1"></div>
                <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
                    styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
                <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
                    (click)="isDuplied()"></p-button>
            </div>
        </ng-template>
    </p-card>
</form>