import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { EntitiesService } from './entities.service';
import { EntitiesFieldsService } from './entities-fields.service';
import { EntitiesDetailFieldsComponent } from './entities-detail/entities-detail-fields/entities-detail-fields.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { EntitiesListComponent } from './entities-list/entities-list.component';
import { EntitiesDetailComponent } from './entities-detail/entities-detail.component';
import { ConfigurationMainPageComponent } from './configuration-main-page/configuration-main-page.component';
import { CountriesListComponent } from './countries/countries-list/countries-list.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RolesDetailComponent } from './roles/roles-detail/roles-detail.component';
import { RolesService } from './roles/roles.service';
import { PermissionsService } from './roles/permissions.service';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { TrackingLogsComponent } from './tracking-logs/tracking-logs.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { MonacoEditorModule, MONACO_PATH } from '@materia-ui/ngx-monaco-editor';
import { TabViewModule } from 'primeng/tabview';
import { HttpConfigInterceptor } from '../core/loader/httpconfig.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AccordionModule } from 'primeng/accordion';
import { CompanyLicenseComponent } from './company-license/company-license.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { EntityFieldMasiveOptionsComponent } from './entities-detail/entities-detail-fields/entity-field-masive-options/entity-field-masive-options.component';
import { MessageModule } from 'primeng/message';
import { TaxRatesListComponent } from './tax-rates/tax-rates-list/tax-rates-list.component';
import { TaxRatesDetailComponent } from './tax-rates/tax-rates-detail/tax-rates-detail.component';
import { AddItemToEntityFieldListComponent } from './entities-detail/entities-detail-fields/add-item-to-entity-field-list/add-item-to-entity-field-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { CountriesDetailComponent } from './countries/countries-detail/countries-detail.component';
import { Tooltip, TooltipModule } from 'primeng/tooltip';
import { ReasonsTransactionsStatusDetailComponent } from './reasons-transactions-status/reasons-transactions-status-detail/reasons-transactions-status-detail.component';
import { ReasonsTransactionsStatusListComponent } from './reasons-transactions-status/reasons-transactions-status-list/reasons-transactions-status-list.component';


@NgModule({
  declarations: [
    EntitiesListComponent,
    EntitiesDetailComponent,
    EntitiesDetailFieldsComponent,
    ConfigurationMainPageComponent,
    CountriesDetailComponent,
    CountriesListComponent,
    RolesListComponent,
    RolesDetailComponent,
    TrackingLogsComponent,
    CompanyLicenseComponent,
    EntityFieldMasiveOptionsComponent,
    TaxRatesListComponent,
    TaxRatesDetailComponent,
    ReasonsTransactionsStatusDetailComponent,
    ReasonsTransactionsStatusListComponent,
    AddItemToEntityFieldListComponent
  ],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    ButtonModule,
    CardModule,
    FontAwesomeModule,
    TableModule,
    FormsModule,
    DropdownModule,
    ConfirmDialogModule,
    InputTextModule,
    FormsModule,
    InputTextareaModule,
    CheckboxModule,
    CalendarModule,
    MultiSelectModule,
    MonacoEditorModule,
    TabViewModule,
    ColorPickerModule,
    AccordionModule,
    CascadeSelectModule,
    MessageModule,
    TranslateModule,
    TooltipModule
  ],
  exports: [
    EntitiesListComponent,
    EntitiesDetailComponent,
    EntitiesDetailFieldsComponent,
    AddItemToEntityFieldListComponent,
    RolesListComponent,
    RolesDetailComponent,
    TaxRatesDetailComponent,
    TaxRatesListComponent,
  ],
  providers: [
    EntitiesService,
    EntitiesFieldsService,
    RolesService,
    PermissionsService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: MONACO_PATH,
      useValue: 'https://unpkg.com/monaco-editor@0.24.0/min/vs'
    }
  ]
})
export class ConfigurationModule { }
