import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, FilterService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesFieldsService } from 'src/app/configuration/entities-fields.service';
import { ReasonsTransactionsStatusService } from 'src/app/configuration/reasons-transactions-status/reasons-transactions-status.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TransactionsService } from '../transactions.service';

@Component({
  selector: 'app-add-transaction-reason',
  templateUrl: './add-transaction-reason.component.html',
  styleUrls: ['./add-transaction-reason.component.scss']
})
export class AddTransactionReasonComponent {
  public reasons: any[] = [];
  public transaction: any = {};

  constructor(
    private reasonTransactionsService: ReasonsTransactionsStatusService,
    private transactionsService: TransactionsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private entitiesFieldsService: EntitiesFieldsService,
    private filterService: FilterService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.transaction = this.config.data.transaction;
    console.log("asdf asdf", this.transaction);
    this.loadReasons();
  }

  loadReasons() {
    this.reasonTransactionsService.all({}).subscribe({
      next: (data: any) => {
        console.log("asdf reasons data list loaded ->", data);
        this.reasons = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  saveTransaction() {
    this.transactionsService.save(this.transaction.id, this.transaction).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close(this.transaction);
        this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  skipReason() {
    this.dynamicDialogRef.close();
  }
}
