<div class="p-grid p-grid-nogutter">
   <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-9">
      <router-outlet></router-outlet>
   </div>
   <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-3">
      <p-card styleClass="layout-sidebar-right">
         <ng-template pTemplate="header">
            <div class="p-card-title">
               Opciones
            </div>
         </ng-template>
         <div>
            <a [routerLink]="['/configuration/entities']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{'entity.entity.name_plural' | translate}}</a>
            <a [routerLink]="['/configuration/mailAccounts']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('mailAccount', 'Cuentas de
               correo')}}</a>
            <a [routerLink]="['/configuration/families']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="families">{{getName('family', 'Familias de
               productos')}}</a>
            <a [routerLink]="['/configuration/countries']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('country', 'Países')}}</a>
            <a [routerLink]="['/configuration/provinces']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('province', 'Provincias')}}</a>
            <a [routerLink]="['/configuration/taskStatus']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('taskStatus', 'Estados de
               actividad')}}</a>
            <a [routerLink]="['/configuration/transactionsStatus']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="pipeline_configuration">{{getName('transactionStatus',
               'Pipelines')}}</a>
            <a [routerLink]="['/configuration/reasonsTransactionsStatus']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('reasonTransactionStatus', 'Motivos de
               pipelines')}}</a>
            <a [routerLink]="['/configuration/templates']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('template',
               'Plantillas')}}</a>
            <a [routerLink]="['/configuration/organizationsTypes']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="create_clients">{{getName('organizationType', 'Tipos de
               organización')}}</a>
            <a [routerLink]="['/configuration/transactionsTypes']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('transactionType', 'Tipos de transacción')}}</a>
            <a [routerLink]="['/configuration/attachmentsTypes/1']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('attachmentType', 'Tipos de adjunto')}}</a>
            <a [routerLink]="['/configuration/attachmentsTypes/2']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{'menu.attachmentsTypesGeneric' | translate}}</a>
            <a [routerLink]="['/configuration/logs']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{'menu.logs' | translate}}</a>
            <a [routerLink]="['/configuration/users']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('user', 'Usuarios')}}</a>
            <a [routerLink]="['/configuration/roles']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="roles_configuration">{{getName('role', 'Roles')}}</a>
            <!--<a [routerLink]="['/configuration/views']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact: true}" *ngIf="personalize_interface">Vistas</a>-->
            <a [routerLink]="['/configuration/zones']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="zonesPermission">{{getName('zone', 'Zonas')}}</a>
            <a [routerLink]="['/configuration/import']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{getName('import', 'Importar
               datos')}}</a>
            <a [routerLink]="['/configuration/taxRates']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('taxRate', 'IVAs')}}</a>
            <a [routerLink]="['/configuration/integrations']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="configuration">{{'menu.integrations' | translate}}</a>
            <a [routerLink]="['/configuration/notifications']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="mail_history">{{'component.notifications.list.title' |
               translate}}</a>
            <a [routerLink]="['/configuration/business']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}" *ngIf="business">{{getName('business', 'Sociedades')}}</a>
            <a [routerLink]="['/configuration/companyLicense']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{'menu.companyLicense' | translate}}</a>
            <a [routerLink]="['/configuration/tasksActionsTypes']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">
               {{getName('taskActionType', 'Tipos de acción de actividad')}}
            </a>
            <a [routerLink]="['/configuration/sectors']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">
               {{getName('sector', 'Sectores')}}
            </a>
            <a [routerLink]="['/configuration/origins']" [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{exact: true}">{{getName('origin', 'Orígenes')}}</a>
         </div>
      </p-card>
   </div>
</div>