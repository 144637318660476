import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReportsService } from '../reports.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { NgForm } from '@angular/forms';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { DatePipe, Location } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UIChart } from 'primeng/chart';
import { UsersService } from 'src/app/users/users.service';
import { ReportsAskUserComponent } from './reports-ask-user/reports-ask-user.component';
import groupBy from 'lodash/groupBy';
import { ZonesService } from 'src/app/configuration/zones/zones.service';
import { RolesService } from 'src/app/configuration/roles/roles.service';
import { ReportClonatorComponent } from './report-clonator/report-clonator.component';
import { ProductsDetailComponent } from 'src/app/products/products-detail/products-detail.component';
import { UsersDetailComponent } from 'src/app/users/users-detail/users-detail.component';
import { FamiliesDetailComponent } from 'src/app/configuration/families/families-detail/families-detail.component';
import { ReportMasiveUpdateComponent } from './report-masive-update/report-masive-update.component';
import { ReportCreateTaskComponent } from './report-create-task/report-create-task.component';
import { ReportsRevalidationActionComponent } from './reports-revalidation-action/reports-revalidation-action.component';
import { ReportMailMarketingComponent } from './report-mail-marketing/report-mail-marketing.component';
import { LoginService } from 'src/app/login/login.service';
import { ComponentService } from '../../core/view/component.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { OrganizationsMeetingsDetailComponent } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TransactionsLinesService } from 'src/app/transactions/transactions-detail/transactions-lines/transactions-lines.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ReportCampaignComponent } from './report-campaign/report-campaign.component';


declare var google: any;
var geocoder: any;

@Component({
  selector: 'app-reports-detail',
  templateUrl: './reports-detail.component.html',
  styleUrls: ['./reports-detail.component.scss']
})
export class ReportsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("formDetails") public formDetails: NgForm;
  //@ViewChild("chartGraph", { static: false }) public chartGraph: UIChart;
  @ViewChild('chartGraph') chartGraph: UIChart;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  public selectedCities: any[];
  public filterValuesOptions: any[] = [];
  public filterConditionsValues: any[] = []

  public report: any = {};
  public entities: any = [];
  public filters: any = {};
  public entity: any = {};
  public results: any = [];
  public valueType: string = "input-text"; onTab
  public filter_options_values = [];
  public filter_options_labels: any;
  public filter_options_value: any;
  public selectedColumns: any[];
  public totalRows: number = 0;
  public rows: any = Array<any>();
  public columns: any = Array<any>();
  private lastGridEvent: any;
  private tabIndexActive: number;
  public loading: boolean = false;
  public options: any;
  public overlays: any[];
  public entity_entityFields: any[];
  public favouriteGraph: boolean = false;
  public favouriteData: boolean = false;
  public favouriteMap: boolean = false;
  public graphForHome: boolean = false;
  public user: any = {};
  public showInHome: boolean = true;
  public filtersGroupOr: any = [];
  public dataGraphTable: any = [];
  public graph_entity_field_total: number;
  public sortDirection: string = "asc";
  public auxResponse: any;
  public orderByOptions = [
    {
      name: this.translateService.instant("component.reports.detail.asc"), value: 'asc'
    },
    { name: this.translateService.instant("component.reports.detail.desc"), value: 'desc' }
  ];
  public graphs_types = [
    { name: this.translateService.instant("component.reports.detail.pie"), value: 'pie' },
    { name: this.translateService.instant("component.reports.detail.bar"), value: 'bar' },
    { name: this.translateService.instant("component.reports.detail.line"), value: 'line' }
  ];
  public visualize_options = [
    { name: this.translateService.instant("component.reports.detail.count"), value: 'count', name_label: this.translateService.instant("component.reports.detail.count_label") },
    { name: this.translateService.instant("component.reports.detail.sum"), value: 'sum', name_label: this.translateService.instant("component.reports.detail.sum_label") },
    { name: this.translateService.instant("component.reports.detail.media"), value: 'media', name_label: this.translateService.instant("component.reports.detail.media_label") },
    { name: this.translateService.instant("component.reports.detail.min"), value: 'min', name_label: this.translateService.instant("component.reports.detail.min_label") },
    { name: this.translateService.instant("component.reports.detail.max"), value: 'max', name_label: this.translateService.instant("component.reports.detail.max_label") },
  ];
  public date_options = [
    {
      name: this.translateService.instant("component.reports.detail.date_select"), value: 'date'
    },
    { name: this.translateService.instant("component.reports.detail.date_now"), value: 'date_now' },
    { name: this.translateService.instant("component.reports.detail.datetime_now"), value: 'datetime_now' },
    { name: this.translateService.instant("component.reports.detail.this_week"), value: 'this_week' },
    { name: this.translateService.instant("component.reports.detail.next_week"), value: 'next_week' },
    { name: this.translateService.instant("component.reports.detail.prev_week"), value: 'prev_week' },
    //{ name: 'Esta quincena', value: 'this_fortnight' },
    //{ name: 'Siguiente quincena', value: 'next_fortnight' },
    { name: this.translateService.instant("component.reports.detail.this_month"), value: 'this_month' },
    { name: this.translateService.instant("component.reports.detail.next_month"), value: 'next_month' },
    { name: this.translateService.instant("component.reports.detail.prev_month"), value: 'prev_month' },
    { name: this.translateService.instant("component.reports.detail.this_quarter"), value: 'this_quarter' },
    { name: this.translateService.instant("component.reports.detail.next_quarter"), value: 'next_quarter' },
    { name: this.translateService.instant("component.reports.detail.prev_quarter"), value: 'prev_quarter' },
    { name: this.translateService.instant("component.reports.detail.this_semester"), value: 'this_semester' },
    { name: this.translateService.instant("component.reports.detail.next_semester"), value: 'next_semester' },
    { name: this.translateService.instant("component.reports.detail.prev_semester"), value: 'prev_semester' },
    { name: this.translateService.instant("component.reports.detail.this_year"), value: 'this_year' },
    { name: this.translateService.instant("component.reports.detail.next_year"), value: 'next_year' },
    { name: this.translateService.instant("component.reports.detail.prev_year"), value: 'prev_year' }
  ];
  public dataGraph: any = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  };
  public dataGraph2: any = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  };
  public yes_no = [
    { name: 'Si', value: 1 },
    { name: 'No', value: 0 },
  ];

  //public colors1: any = ["#90cdc4", "#E7E9ED", "#FFCE56", "#FF6384", "#36A2EB", "#93dd9c", "#9d9d9d", "#745914", "#103a3a", "#87132c"];
  public colors1: any = ["#BFB48F", "#E89B01", "#E2660E", "#AF2734", "#D687B8", "#904C83", "#8282BF", "#434488", "#267EAB", "#85CDD7", "#26B4AD", "#7FB780", "#2C7130", "#1B505A", "#5E3030"];//ma han pasado la lista de coloresg2pm el 14/11/2022
  public chart_type: string = "bar";

  public basicOptions: any = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    }
  };

  public selectTabIndex: number = 0;
  public entitiesFields: any[] = [];
  public entitiesToFilter: any[] = [];
  public entitiesToTable: any[] = [];
  public entitiesToFilterNumber: any[] = [];
  public temporalCombo: any[] = [];
  public showMap: boolean = true;
  public itemsAction: any[];

  //permisos:
  public graphics_in_reports: boolean;
  public create_reports: boolean;
  public mail_marketing: boolean;
  public campaigns_permission: boolean;
  public view_map_info: boolean;
  public zonesPermission: boolean;
  public write_report: boolean;
  public showDateSelector: boolean = false;
  public markerPositions: google.maps.LatLngLiteral[] = [];
  public infoContent: string;
  public resumen_table_records: string;
  public params: any = {};

  constructor
    (
      private loginService: LoginService,
      private entitiesService: EntitiesService,
      private reportsService: ReportsService,
      private dialogService: DialogService,
      private messageService: MessageService,
      private coreDialogService: CoreDialogService,
      private route: ActivatedRoute,
      private router: Router,
      private coreFormService: CoreFormService,
      private viewsService: ViewsService,
      private usersService: UsersService,
      private zonesService: ZonesService,
      private rolesService: RolesService,
      private datePipe: DatePipe, private componentService: ComponentService,
      private transactionLinesService: TransactionsLinesService,
      private transactionsService: TransactionsService,
      private _location: Location,
      private translateService: TranslateService) {

  }
  center: google.maps.LatLngLiteral = { lat: 40.4166400, lng: -3.7032700 };
  zoom = 6;
  display: google.maps.LatLngLiteral;

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }


  generateColor() {
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  }

  ngOnInit(): void {

    //this.lastGridEvent.first = 0;
    this.graphics_in_reports = this.loginService.hasPermission("GRAPHICS_IN_REPORTS");
    this.create_reports = this.loginService.hasPermission("REPORTS_CREATE");
    this.mail_marketing = this.loginService.hasPermission("REPORTS_ACTIONS_MAIL_MARKETING");
    this.campaigns_permission = this.loginService.hasPermission("REPORTS_ACTIONS_CAMPAIGNS")
    this.view_map_info = this.loginService.hasPermission("VIEW_MAP_INFO");
    this.zonesPermission = this.loginService.hasPermission("ZONES_READ");

    this.write_report = this.loginService.hasPermission("REPORTS_WRITE") || this.loginService.hasPermission("REPORTS_WRITE_ROLES") || this.loginService.hasPermission("REPORTS_WRITE_ZONES");
    if (!this.write_report) this.selectTabIndex = 1;

    this.route.params.subscribe(params => {
      this.loadData(params['id']);
      this.loadUserReportInfo(params['id']);
    });
    /*this.options = {
      center: { lat: 40.4166400, lng: -3.7032700 },
      zoom: 6
    };*/
    this.loadEntities();
    this.itemsAction = [
      {
        label: this.translateService.instant("component.reports.detail.refresh"), icon: 'pi pi-refresh', command: () => {
          this.onReportTabOpen(this.lastGridEvent);
        }
      },
      {
        label: this.translateService.instant("component.reports.detail.export_excel_view"), icon: 'pi pi-file-excel', command: () => {
          if (this.report.id) {
            var params = {};
            this.loading = true;
            this.reportsService.loadData(this.report, null, null).subscribe({
              next: (data: any) => {
                var results = data;
                this.exportExcel(results);
              },
              error: (error: any) => {
                this.messageService.add({ severity: "error", detail: error.Message });
                this.loading = false;
              }
            });
          }
        },
        visible: this.loginService.hasPermission("EXPORT_REPORT")
      },
      {
        label: this.translateService.instant("component.reports.detail.export_excel_all"), icon: 'pi pi-file-excel', command: () => {
          this.exportExcelAllFields();
        },
        visible: this.loginService.hasPermission("EXPORT_ALL"),
        code: 'export_excel_all'
      },
      {
        label: this.translateService.instant("component.reports.detail.delete"), icon: 'pi pi-trash', command: () => {
          this.deleteData();
        },
        visible: this.loginService.hasPermission("REPORTS_ACTIONS_DELETE")
      },
      { separator: true },
      {
        label: this.translateService.instant("component.reports.detail.update"), icon: 'pi pi-pencil', command: () => {
          this.updateDataMasive();
        },
        visible: this.loginService.hasPermission("REPORTS_ACTIONS_UPDATE")
      },
      { separator: true },
      {
        label: this.translateService.instant("component.reports.detail.create_task"), icon: 'pi pi-plus', command: () => {
          this.createTask()
        },
        visible: this.loginService.hasPermission("REPORTS_ACTIONS_CREATE_TASK")
      }
    ];
    if (this.mail_marketing) {
      this.itemsAction.push(
        { separator: true },
        {
          label: this.translateService.instant("component.reports.detail.mail_marketing"), icon: 'pi pi-envelope', command: () => {
            this.mailMarketing();
          }
        }
      );
    }
    if (this.campaigns_permission) {
      this.itemsAction.push(
        { separator: true },
        {
          label: this.translateService.instant("component.reports.detail.campaigns"), icon: "pi pi-envelope", command: () => {
            this.campaigns();
          }
        }
      );
    }
  }

  checkIfTYpeGroupedIsDate(groupedType) {
    return (groupedType == "date" || groupedType == "datehour" ||
      groupedType == "datetime" || groupedType == "week" ||
      groupedType == "month" || groupedType == "trimester" || groupedType == "semester" || groupedType == "year");
  }

  loadData(id: number) {
    if (id != 0) {
      this.reportsService.get(id).subscribe({
        next: (data: any) => {
          this.report = data;
          this.loadEntitiesFields();
          if (typeof (this.report.metadata?.graph?.entity_field_grouped2) === "undefined") this.report.metadata.graph.entity_field_grouped2 = {};
          if (typeof (this.report.metadata?.report.columns) === "undefined") this.report.metadata.report.columns = [];//Por si existe alguno roto
          if (typeof (this.report.metadata?.report.order) === "undefined") this.report.metadata.report.order = { "direction": this.sortDirection, "column_key": null };
          //Muestra el bloque y pestaña de mapa sólo si la entidad es Organization o Actividad:
          this.showMap = (this.report.entity.code == "organization" || this.report.entity.code == "task");//(this.report.entity_id == 1 || this.report.entity_id == 4);

          //Para mostrar el groupBy de los gráficos si son de alguno de los tipos de fecha.
          if (this.report.metadata?.graph?.entity_field_grouped && this.checkIfTYpeGroupedIsDate(this.report.metadata?.graph.entity_field_grouped.entity_field_grouped_type)) this.showDateSelector = true;

          if (typeof (this.report.metadata?.graph?.order) === "undefined") this.report.metadata.graph.order = {};
          this.loadMetadata();

          //Si el informe es de tareas se oculta la opción de mostrar todo, porque hay demasiadas left joins y casca.
          if (this.report.entity?.code == 'task') {
            var aux = this.itemsAction.filter(m => m.code == 'export_excel_all');
            aux[0].visible = false;
          }

        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.report = {
        metadata: {
          filters: {
            filtersGroupAND: [{
              filtersGroupOR: [{}]
            }]
          },
          report: { columns: [] },
          graph: {
            type: "bar", visualize: "count", entity_field: {}, entity_field_grouped: {}, entity_field_grouped2: {}, order: {}
          },
          map: {}
        }
      };
    }
  }

  loadUserReportInfo(report_id: number) {
    this.favouriteGraph = false;
    this.favouriteData = false;
    this.favouriteMap = false;
    this.usersService.get(parseInt(localStorage.getItem("userId"))).subscribe({
      next: (data: any) => {
        this.user = data;

        if (this.user != null) {
          this.user.metadata?.home[0].reports.forEach(element => {
            if (element.url == report_id + "#data") this.favouriteData = true;
            if (element.url == report_id + "#graph") this.favouriteGraph = true;
            if (element.url == report_id + "#map") this.favouriteMap = true;
          });
          if (typeof (this.user.metadata?.home[0].graph) !== "undefined") {
            this.user.metadata?.home[0].graph.forEach(element => {
              if (element == report_id) this.graphForHome = true;
            });
          }
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  loadGraphInfo() {
    if (typeof (this.report.metadata.graph.entity_field_grouped) !== "undefined") {
      //esto raro es porque si no no rellena los combos
      var id2 = this.report.metadata.graph.entity_field_grouped.entity_field_grouped_id;
      this.report.metadata.graph.entity_field_grouped.entity_field_grouped_id = null;
      setTimeout(() => { this.report.metadata.graph.entity_field_grouped.entity_field_grouped_id = id2; }, 500);
      //fin de lo raro        
    };
    if (typeof (this.report.metadata.graph.entity_field_grouped2) !== "undefined") {
      //esto raro es porque si no no rellena los combos
      var id3 = this.report.metadata.graph.entity_field_grouped2.entity_field_grouped_id;
      this.report.metadata.graph.entity_field_grouped2.entity_field_grouped_id = null;
      setTimeout(() => { this.report.metadata.graph.entity_field_grouped2.entity_field_grouped_id = id3; }, 500);
      //fin de lo raro        
    };
    if (typeof (this.report.metadata.graph.entity_field) !== "undefined") {
      //esto raro es porque si no no rellena los combos
      var id = this.report.metadata.graph.entity_field.entity_field_id;
      this.report.metadata.graph.entity_field.entity_field_id = null;
      setTimeout(() => { this.report.metadata.graph.entity_field.entity_field_id = id; }, 500);
      //fin de lo raro        
    }
  }

  loadFilters() {

    if (typeof (this.report.metadata?.filters) !== "undefined" && this.report.metadata?.filters != null && typeof (this.report.metadata?.filters.filtersGroupAND) !== "undefined" && this.report.metadata?.filters.filtersGroupAND.length > 0) {
      let i = 0; let j = 0;
      const obj2 = { ...this.report.metadata?.filters };

      this.report.metadata?.filters.filtersGroupAND?.forEach(groupAnd => {
        groupAnd.filtersGroupOR.forEach(element => {
          //esto raro es porque si no no rellena los combos
          var id = element.entity_field_id;
          var field = this.entity.fields.find(m => m.id == id);

          element.entity_field_id = null;
          setTimeout(() => {
            if (field != null) {//lo añadimos solo si existe, no vaya a ser que se haya borrado.
              element.entity_field_id = id;
            } else {
              groupAnd.filtersGroupOR.splice(j, 1);

            }
            j++;
          }, 100);
          //fin de lo raro

          //si es combo cargamos las opciones
          if (field.control_type == "dropdown" || field.control_type == "dropdown-multiple") {
            if (field.use_for_report) this.getComboOptions(field, null);
          }

        });
        setTimeout(() => { i++; }, 100);
      });
    } else {
      if (typeof (this.report.metadata.filters) === "undefined") this.report.metadata.filters = [];
      this.report.metadata.filters.filtersGroupAND = [{
        filtersGroupOR: [{}]
      }];
    }

  }
  getFilterOptionsOrValueType(element, filter) {
    if (typeof (this.entity.fields) !== "undefined") {
      let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
      if (field.length > 0) {
        //if (filter) return this.filterOptions(field[0].data_type, field[0].control_type, field[0]); ya no se llama desde la vista.
        if (this.valueTypes(field[0].data_type, field[0].control_type) == 'input-datetime' && element.value_date == 'date') {
          //element.value = new Date(element.value);
        }
        if (!filter) return this.valueTypes(field[0].data_type, field[0].control_type);
      }
    }
  }

  dateToIso($event: Date, i: number, j: number) {
    this.report.metadata.filters.filtersGroupAND[i].filtersGroupOR[j].value = new Date($event.setSeconds(0)).toISOString();
  };

  getNameEntityField(element) {
    if (typeof (this.entity.fields) !== "undefined") {


      let field = this.entity.fields.filter(m => m.id == element);
      if (field.length > 0) {
        return field[0].name;
      }
    }
  }

  addColumns(tabOpen) {
    //if (typeof (this.report.metadata.report.columns) === "undefined") this.report.metadata.report.columns = [];
    let i = this.report.metadata.report.columns.length;
    this.report.metadata.report.columns = this.selectedColumns;

    //Comprobamos si los items mostrados están en la ordenación, sino está, se borra la ordenación-
    var existe = false;
    this.selectedColumns.forEach((item) => {
      var aux = item.entity_field_model_property.split(".");
      let variableCheck = aux[0] + "_" + aux[1] + "_" + item.entity_field_id;
      if (this.report.metadata.report.order?.column_key == variableCheck) {
        existe = true;
      }
    });
    console.log(this.report.metadata.report);
    if (!existe) if (this.report.metadata.report != null && this.report.metadata.report.order != null) this.report.metadata.report.order.column_key = null;
    if (tabOpen) this.onReportTabOpen(this.lastGridEvent)
    if (tabOpen) this.save();

  }

  loadEntities() {
    this.entitiesService.all({ for_reports: true }).subscribe({
      next: (data: any) => {
        this.entities = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadEntitiesFields() {
    let id = this.report.entity_id ?? 0;
    this.reportsService.fields(id).subscribe({
      next: (data: any) => {

        this.entity = data;
        this.entity.fields.forEach(field => {
          //cargamos en un temporal todos los datos de los posibles combos
          //quitado, ahora se carga al elegir el filtro y/o al pintar los filtros
          /*if (field.control_type == "dropdown" || field.control_type == "dropdown-multiple") {
            if (field.use_for_report) this.getComboOptions(field, null);
          }*/

          //Creamos el array de los filtros porque no se puede realizar una llamada (getFilterOptionsOrValueType), ya que hay que darle dos veces para seleccionarlo.
          if (typeof this.filterConditionsValues[field.id] == "undefined") this.filterConditionsValues[field.id] = [];
          this.filterConditionsValues[field.id] = this.filterOptions(field.data_type, field.control_type, field);
        });
        let i = 0;
        this.report.metadata?.report?.columns?.forEach(col => {
          if (this.entity.fields.filter(m => m.id == col.entity_field_id).length == 0) {
            this.report.metadata.report.columns.splice(i, 1);
            //lo eliminamos 
          }
          i++;
        })

        this.entitiesToFilter = this.getSelectableEntityFields();
        this.entitiesToTable = this.getSelectableEntityFields(true, null, true);
        this.entitiesToFilterNumber = this.getSelectableEntityFields(false, true);
        this.loadFilters();
        this.loadGraphInfo();
        setTimeout(() => {
          this.selectTab();
        }, 500);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  selectTab() {
    //leer pestaña seleccionada
    var parts = location.href.split("#");
    var tabCode = parts[parts.length - 1];
    let newTabIndex = this.selectTabIndex;
    var innerText = "";
    if (tabCode == "data") {
      newTabIndex = 1;
      innerText = "Informe";
    } else if (tabCode == "graph") {
      newTabIndex = 2;
      innerText = "Grafico";
    } else if (tabCode == "map") {
      newTabIndex = 3;
      innerText = "Mapa";
    }
    /*if (newTabIndex > 0 && !this.write_report) newTabIndex--;
    if (newTabIndex > 0 && tabCode == "map" && !this.graphics_in_reports) newTabIndex--;*/
    this.selectTabIndex = newTabIndex;
    this.onTabOpen({ index: this.selectTabIndex, originalEvent: { target: { innerText: innerText } } });
  }
  /*addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions.push(event.latLng.toJSON());
  }*/

  loadMap() {

    let bounds = new google.maps.LatLngBounds();
    geocoder = new google.maps.Geocoder();
    //this.overlays = [];
    this.reportsService.loadDataMap(this.report).subscribe(
      data => {
        if (data.values.length == 0) {

          this.options = {
            center: { lat: 40.4166400, lng: -3.7032700 },
            zoom: 6
          };
        } else {
          data.values.forEach(result => {

            const marker = new google.maps.Marker({
              data: result,
              position: { lat: result.lat, lng: result.lng },
              title: result.name,
              label: {
                color: "black",
                text: result.name
              },
              options: {
                //icon: image,
                draggable: false
              }
              /*position: { lat: result.lat, lng: result.lng },
              title: result.name*/
            });
            this.markerPositions.push(marker);

          });
        }
      },
      error => {
        this.messageService.add({ severity: "error", detail: error.Message });
        this.loading = false;
      }
    );

  }
  onDragend(m, event) {
    //this.markers[this.determineIndex(m)].position = event.latLng;
  }

  generateInfoWindow(marker: MapMarker, data) {
    //this.infoContent = "";
    this.closeInfoWindow();
    //Las cosas a mostrar en la ventana del marcador, se ponen en la siguiente línea como String:
    let infoWindowContent = "";
    if (data.data.name) infoWindowContent += "<b>" + data.data.name + "</b>";
    if (data.data.typeOrganization) infoWindowContent += " (" + data.data.typeOrganization + ")";
    if (data.data.address) infoWindowContent += "<br/>" + data.data.address + "";
    if (data.data.city && data.postal_code) infoWindowContent += "<br/>" + data.data.postal_code;
    if (data.data.city) infoWindowContent += " " + data.data.city + "";
    if (data.data.email) infoWindowContent += "<br/>" + data.data.email + "";
    if (data.data.name) {
      //es una organizacion...
      infoWindowContent += "<br><br><a target='_blank' href='/organizations/" + data.data.organization_type_id + "/" + data.data.id + "'>" + this.translateService.instant("component.reports.detail.see_detail") + "</a>";
    }
    this.infoContent = infoWindowContent;
    this.infoWindow.open(marker);
  }
  closeInfoWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    };
  }

  loadGraph() {
    this.chart_type = this.report.metadata.graph.type;
    this.clearGraphData();

    this.reportsService.loadDataGraph(this.report).subscribe(
      data => {
        this.graph_entity_field_total = 0;
        this.dataGraphTable = data.values;

        this.dataGraph.display = false;
        this.dataGraph.labels = this.dataGraphTable.map(m => m.label).filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });

        let grouped2 = this.dataGraphTable.map(m => m.label2).filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });


        this.dataGraph.labels.forEach(label => {
          let i = 0;
          grouped2.forEach(g2 => {
            let valres = data.values.filter(m => m.label == label && m.label2 == g2);
            var randomColor = this.generateColor();

            if (this.dataGraph.datasets.length - 1 < i) {
              var aux = {
                data: [],
                label: "",
                backgroundColor: (this.colors1[i] ? this.colors1[i] : randomColor), hoverBackgroundColor: (this.colors1[i] ? this.colors1[i] : randomColor)
              };
              this.dataGraph.datasets.push(aux);
            }
            this.dataGraph.datasets[i].data.push(valres.length > 0 ? valres[0].value : 0);
            this.graph_entity_field_total = this.graph_entity_field_total + (valres.length > 0 ? valres[0].value : 0);
            this.dataGraph.datasets[i].label = (typeof (g2) === "undefined" ? this.getGraphInfo(this.report.metadata?.graph.entity_field?.entity_field_id, null, false) : g2);
            if (typeof (g2) === "undefined") {
              this.dataGraph.datasets[i].backgroundColor = this.colors1[0];
              this.dataGraph.datasets[i].hoverBackgroundColor = this.colors1[0];
            }
            i++;
          });

        });

        //formateamos el resultado final, REVISARRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR
        this.graph_entity_field_total = this.getGraphInfo(this.report.metadata?.graph.entity_field?.entity_field_id, this.graph_entity_field_total, true)
        // setTimeout(() => {
        //TODO: igual sólo es necesario ponerle el tamaño de height en los moviles, habria que ocmprobarlo aqui y en la vista.
        this.chartGraph.height = "300px";
        this.chartGraph.refresh();
        //}, 100);

      },
      error => {
        console.log(error);

        this.messageService.add({ severity: "error", detail: error.error.title });
        this.loading = false;
      }
    );
  }
  getGraphInfo(entity_field_id, value, returnValue) {
    //O devuelve el nombre de la columna, o el dato formateado.
    let result = "";
    if (typeof (this.entity.fields) !== "undefined") {

      if (this.report.metadata?.graph.visualize == "count") {
        if (typeof (entity_field_id) !== "undefined" && entity_field_id != null) {
          let field = this.entity.fields.find(m => m.id == entity_field_id);
          if (!returnValue && field != null) { result = field.description; }
        } else {
          if (!returnValue) {
            result = "Total";
          } else {
            result = value;
          }
        }
      } else {
        let field = this.entity.fields.find(m => m.id == entity_field_id);
        if (field != null) {
          if (!returnValue) { return field.description; } //devolvemos la cabecera.
          let property = field.model_property;
          if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;
          let formatFn = field.formatFn;
          if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
            formatFn = field.configuration.formatFn;
          }
          result = value;
          if (formatFn != null) {
            var format = formatFn.split("|");
            if (format[0] == "date") result = this.formatDate(value, format);
            if (format[0] == "suffix") result = this.formatSuffix(value, format);
            if (format[0] == "phone") result = this.formatPhone(value, format);
            if (format[0] == "mail") result = this.formatMail(value, format);
            if (format[0] == "www") result = this.formatWWW(value, format);
            if (format[0] == "badge") {
              //result = this.formatBadge(field, value, format, null);
            }
            if (format[0] == "image") result = this.formatImage(field, format);
            if (format[0] == "currency") result = this.formatCurrency(value, format);
            if (format[0] == "number") result = this.formatNumber(value, format);
          }
          if (returnValue) if (result == null || result == "") result = " ";
        }
      }
    }
    return result;

  }

  clearGraphData() {
    this.dataGraph = {
      labels: [],
      legend: {
        labels: {
          color: '#495057'
        }
      },
      datasets: [
        {
          data: []/*,
          label: "",*/
        }
      ],
      scales: {
        y: {
          ticks: {
            min: 0,
            max: 3,
            color: '#495057'
          },
        }

      }
    };
    if (this.chart_type == "bar") {
      this.dataGraph.datasets[0].backgroundColor = this.colors1[0];
      this.dataGraph.datasets[0].hoverBackgroundColor = this.colors1[0];
    } else if (this.chart_type == "pie") {
      this.dataGraph.datasets[0].backgroundColor = this.colors1[0];
      this.dataGraph.datasets[0].hoverBackgroundColor = this.colors1[0];
    } else {
      this.dataGraph.datasets[0].borderColor = this.colors1[0];
      this.dataGraph.datasets[0].fill = false;
    }

  }

  composeCascade(entitiesResult, aux, level) {


    let entitiesResultLevel = entitiesResult.filter(m => m.model_property_path.length == level);
    const a = groupBy(entitiesResultLevel, function (n) {
      return n.model_property_path[level - 1].name;
    });
    let claves = Object.keys(a);

    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      let allNamesArray = a[clave][0].model_property_path.map(n => n.name);
      let allNames = allNamesArray.filter(Boolean).join(" / ");
      let aux2 = { entity: allNames, fields: a[clave] };
      aux.push(aux2);
    }
    let entitiesResultMoreLevel = entitiesResult.filter(m => m.model_property_path.length >= level + 1);

    if (entitiesResultMoreLevel.length > 0) {
      aux = this.composeCascade(entitiesResultMoreLevel, aux, level + 1)
    }
    return aux;
  }

  getSelectableEntityFields(filterIfAllreadyExist: boolean = false, onlyNumbers: boolean = false, forEntitiesTable: boolean = false) {
    if (this.entity?.fields != null) {
      //obtener entidades
      let entities = [];
      let visibleEntityFields = [];
      this.entity.fields.forEach(field => {
        if (field.model_property == "zone_id") {
        }
        if (this.componentService.isVisibleFieldCustom({ entityField: field }, null)) {
          visibleEntityFields.push(field);
          if (entities.find(m => m.code == field.entity_code) == null) {
            //Lo cogemos del field porque lo traemos para las entidades que no son la principal.
            entities.push({ code: field.entity_code, name: field.entity_name, shortname: field.shortname });
          }
        }
      });

      let entityFields = [];
      //entityFields = this.entity.fields.filter(m => m.use_for_report);
      entityFields = visibleEntityFields.filter(m => m.use_for_report);


      //si solo filtrar por lo que son de tipo numero
      if (onlyNumbers) {
        entityFields = visibleEntityFields.filter(m => m.use_for_report && m.data_type == 'number' && m.control_type != 'dropdown');
        //entityFields = this.entity.fields.filter(m => m.use_for_report && m.data_type == 'number' && m.control_type != 'dropdown');
      }

      let data = [];
      entities.forEach((entity) => {
        let fields = entityFields.filter(m => m.entity_code == entity.code);
        fields.forEach((f) => {
          data.push({
            id: f.id,
            entity_field_id: f.id,
            entity_field_model_property: f.full_model_property,
            entity_field_model_property_label: f.full_model_property_label,
            description: (entity.shortname ? entity.shortname : entity.name) + " / " + f.description,
            label: (entity.shortname ? entity.shortname : entity.name) + " / " + f.description//(typeof (f.label) === "undefined" ? "" : f.label)
          });
        })

      });

      if (filterIfAllreadyExist) {
        //filtramos que no aparezcan si ya están añadidos a la tabla.
        if (typeof (this.report.metadata.report.columns) !== "undefined") {
          this.selectedColumns = data.filter(el => this.report.metadata?.report.columns.map(m => m.entity_field_id).indexOf(el.id) !== -1);
        }
      }

      return data;
    } else {
      return [];
    }
  }

  getComboOptions(field, text) {
    if (field.configuration.options?.type == "api") {
      var url = "/api/" + field.configuration.options.url;
      if (text == "undefined") text = null;
      this.temporalCombo[field.id] = [];
      this.reportsService.customVoidCombos(url, { text: text }).subscribe(
        data => {

          this.temporalCombo[field.id] = data.rows;
          if (field.configuration.options.url == "users/combo" || field.configuration.options.url == "users") {
            //Añadimor el usuario actual
            this.temporalCombo[field.id].push({ id: -1, name: this.translateService.instant("general.actual_user") });
          }
          if (field.configuration.options.url == "transactionsStatus") {

            //Filtramos asi para que siempre pillen los de la transacción correspondiente. (independientemete de si es oportunidad/venta o de nueva creación)
            this.temporalCombo[field.id] = this.temporalCombo[field.id].filter(function (e) { return e.transactionType.code == field.entity_code });

            /*if (field.entity_code == "oportunity" || field.entity_code == "sale") {
              let transaction_type_id = (field.entity_code == "oportunity" ? 1 : 2);

              this.temporalCombo[field.id] = this.temporalCombo[field.id].filter(function (e) { return e.transaction_type_id == transaction_type_id });
            } else {
              //si no es oportunidad o trasnacción muestro los que estarian relaiconados con el code...
              
            }*/
          }
          //

          if (field.data_type == "list" || field.data_type == 'list_multiple') {
            field.configuration.options.items.forEach(element => {
              let id = field.configuration.field_value;
              let text = field.configuration.field_text;
              if (typeof this.filterValuesOptions[element.entity_field_id] === "undefined") this.filterValuesOptions[element.entity_field_id] = [];
              this.filterValuesOptions[element.entity_field_id].push({ [id]: element.code, [text]: element.text });

            });
          } else if (field.data_type == "number") {
            //if (typeof this.filterValuesOptions[field.id] === "undefined") 
            this.filterValuesOptions[field.id] = [];
            this.temporalCombo[field.id].forEach(element => {

              element.text = element[field.configuration.field_text];
              element.code = element[field.configuration.field_value];
              this.filterValuesOptions[field.id].push(element);
            })
          }
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      field.configuration.options.items.forEach(e => {
        e.name = e.text;
        e.id = e.code;
      })
      this.temporalCombo[field.id] = field.configuration.options.items
      if (typeof this.filterValuesOptions[field.id] === "undefined") this.filterValuesOptions[field.id] = [];
      this.temporalCombo[field.id].forEach(element => {
        this.filterValuesOptions[field.id].push(element);
      })
    }

  }

  onFilter(event, groupOr) {
    var ent = this.entity.fields.filter(m => m.id == groupOr.entity_field_id)[0];
    if (event.filter !== null && event.filter.length >= 3) this.getComboOptions(ent, event.filter);
    if (event.filter === null) this.getComboOptions(ent, "");
  }

  onRowReorder(event) {
    let i = 0;
    this.report.metadata?.report.columns.forEach(element => {
      element.order = i;
      i++;
    });

  }
  onColReorder($event) {
    moveItemInArray(this.report.metadata.report.columns, $event.dragIndex, $event.dropIndex);
    this.save(false, false);
  }

  cancel() {
    this.router.navigate(["/reports"]);
  }

  onFieldTableChange(event: any, fieldConfig: any) {
    /*let field = this.entity.entityFields.filter(m=>m.model_property == event.value);
    if(field.length>0){
      fieldConfig.entityField = field[0];
    }*/
  }

  save(navigate = false, show_message = true) {

    //Si tiene el check de gráfico...
    if (this.report.metadata?.graph.show != null && this.report.metadata?.graph.show != false) {
      var invalidMessage = "";
      if (this.report.metadata?.graph.type == null || this.report.metadata?.graph.type == "") {
        invalidMessage += this.translateService.instant("component.reports.detail.graphic_type_mandatory");
      }
      if (this.report.metadata?.graph.entity_field_grouped.entity_field_grouped_id == null || this.report.metadata?.graph.entity_field_grouped.entity_field_grouped_id == "") {
        if (invalidMessage != "") invalidMessage += "\n-";
        invalidMessage += this.translateService.instant("component.reports.detail.grouped_mandatory");
      }
      if (this.report.metadata?.graph.visualize == null || this.report.metadata?.graph.visualize == "") {
        if (invalidMessage != "") invalidMessage += "\n-";
        invalidMessage += this.translateService.instant("component.reports.detail.visualize_mandatory");
      } else if (this.report.metadata?.graph.visualize != "count") {
        if (this.report.metadata?.graph.entity_field.entity_field_id == null || this.report.metadata?.graph.entity_field.entity_field_id == "") {
          if (invalidMessage != "") invalidMessage += "\n-";
          invalidMessage += this.translateService.instant("component.reports.detail.entity_field_mandatory");
        }
      }
      if (this.report.metadata?.graph.order != null && this.report.metadata?.graph.order?.order_by == null) {
        this.report.metadata.graph.order = {};
      }
      if (invalidMessage != "") {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: invalidMessage });
        return;
      }
    }
    if (this.write_report) {
      if (this.report.name != null && this.report.name != "" && this.report.entity_id != null && this.report.entity_id != 0) {

        this.buildMetadata();
        if (this.report.metadata?.graph.visualize == "count") {
          this.report.metadata.graph.entity_field = {};
        }
        //guardamos las columnas seleccionadas
        var i = 0;
        if (typeof this.report.id !== "undefined") {

          if (this.coreFormService.validate(this.formDetails)) {
            this.reportsService.save(this.report.id, this.report).subscribe(
              data => {
                if (show_message) {
                  if (this.report.id != 0 && typeof this.report.id !== "undefined") {
                    this.translateService.instant("component.reports.detail.save_report_correct")
                  } else {
                    this.translateService.instant("component.reports.detail.add_report_correct")
                  }
                }
                if (navigate) this.router.navigate(['/reports']);
              },
              error => {
                this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
              }
            );
          }
        } else {
          if (this.coreFormService.validate(this.formDetails)) {
            this.reportsService.add(this.report).subscribe({
              next: (data: any) => {
                this.report = data;
                if (show_message) this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.save_report_correct") });
                if (navigate) {
                  this.router.navigate(['/reports']);
                } else {
                  this.router.navigate(['/reports/' + this.report.id]);
                }
              },
              error: (error: any) => {
                this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
              }
            });
          }
        }
      } else {
        this.errorNoName();
      }
    }
  }

  clearAll() {
    this.filters = {};
    this.filter_options_values = [];
  }
  addFilterItemAND() {
    this.report.metadata.filters.filtersGroupAND.push({ filtersGroupOR: [{}] });
  }
  addFilterItemOR(i) {
    this.report.metadata.filters.filtersGroupAND[i].filtersGroupOR.push({});
  }
  deleteFilterOR(i, j) {
    this.report.metadata.filters.filtersGroupAND[i].filtersGroupOR.splice(j, 1);
  }
  deleteFilterAND(i) {
    this.report.metadata.filters.filtersGroupAND.splice(i, 1);
  }

  deleteAllColumns() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reports.detail.delete_all_fields_confirmation"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("general.confirmation_delete") });
        this.report.metadata.report.columns = [];
        this.entitiesToTable = this.getSelectableEntityFields(true, null, true);
      },
      reject: () => {

      }
    });
  }

  checkIfExistInOrder(item) {
    //Comprobamos si está en la columna de ordenación, porque sino falla.
    var aux = item.entity_field_model_property.split(".");
    let variableCheck = aux[0] + "_" + aux[1] + "_" + item.entity_field_id;
    if (this.report.metadata.report.order?.column_key == variableCheck) {
      this.report.metadata.report.order.column_key = null;
    }
  }

  deleteColumnItem(item) {
    var i = 0;
    this.report.metadata.report.columns.forEach(element => {
      if (element == item) {
        this.report.metadata.report.columns.splice(i, 1);
      }
      i++;
    });
    this.checkIfExistInOrder(item);
    //añadimos las cosas.
    //if(typeof (this.report.metadata.report.columns)!=="undefined") {
    this.entitiesToTable = this.getSelectableEntityFields(true, null, true);
    //}
  }
  filterOptions(data_type: string, control_type: string, element: any) {
    let filter_options: any;
    if (data_type == "string") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.contains"), value: 'contains' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.not_contains"), value: 'not_contains' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.>"), value: '>' },
        { name: this.translateService.instant("component.reports.detail.>="), value: '>=' },
        { name: this.translateService.instant("component.reports.detail.<"), value: '<' },
        { name: this.translateService.instant("component.reports.detail.<="), value: '<=' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (data_type == "list") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (data_type == "number" && control_type == "dropdown") {
      //para las forenkeys... 
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    } else if (data_type == "list_multiple") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.contains"), value: 'contains' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    } else {
      //boolean
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' }
      ];
    }
    return filter_options;
  }


  getFilterComboOptionsText(element: any, field_text: boolean) {
    let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
    if (field.length > 0) {
      if (field_text) {
        return field[0].configuration.field_text;
      } else {
        return field[0].configuration.field_value;
      }
    }
  }

  getFilterOptionsData(element: any) {
    let filterOptions: any[] = [];

    let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
    if (field.length > 0) {
      if (field[0].data_type == "list") {
        field[0].configuration.options.items.forEach(element => {
          let id = field[0].configuration.field_value;
          let text = field[0].configuration.field_text;
          filterOptions.push({ [id]: element.code, [text]: element.text });
        });
      } else if (field[0].data_type == "number") {
        return this.temporalCombo[element.entity_field_id];
      }
    }

    return filterOptions;
  }

  valueTypes(data_type: string, control_type: string) {
    let valueType = "";
    if (data_type == "string") {
      valueType = 'input-text';
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      valueType = (data_type == "number" ? 'input-number' : "input-datetime");
    }
    else if (data_type == "list") {
      valueType = "list";
    } else if (data_type == "list_multiple") {
      valueType = 'dropdown-multiple';
    } else if (data_type == "boolean" && control_type == "checkbox") {
      valueType = 'switch';
    } else {
      valueType = 'input-text';
    }

    if (control_type == "dropdown") {
      valueType = 'input-dropdown';
    }
    return valueType;
  }

  unsorted() { }


  onChangeEntity() {
    //Recargamos el combo de datos.
    this.entities.forEach(element => {
      if (element.id == this.report.entity_id) {
        this.report.entity = element;
        //revisamos si existen filtros iniciales.
        if (this.report.entity.metadata.report.filters != null) {
          if (typeof (this.report.metadata.filters) === "undefined") this.report.metadata.filters = [];
          this.report.metadata.filters = this.report.entity.metadata.report.filters;
        }
        this.showMap = (this.report.entity_id == 1 || this.report.entity_id == 4);
        this.loadEntitiesFields();
      }
    });
    //this.entitiesToFilter = this.getSelectableEntityFields();
    //this.entitiesToTable = this.getSelectableEntityFields(true);
  };

  onFieldChange(event: any, i: number, j: number) {
    if (typeof (this.entity) !== "undefined") {
      let field = this.entity.fields.find(m => m.id == event.value);

      if (field != null) {
        //si es combo cargamos sus opciones
        if (field.control_type == "dropdown" || field.control_type == "dropdown-multiple") {
          if (field.use_for_report) this.getComboOptions(field, null);
        }
        this.report.metadata.filters.filtersGroupAND[i].filtersGroupOR[j].entity_field_model_property = field.full_model_property;
      }
    }
  };

  onShortChange(event: any) {
    let field = this.entity.fields.filter(m => m.id == event.value);
    if (field.length > 0) {
      this.report.metadata.graph.order.order_by = field[0].id;
      this.report.metadata.graph.order.order_by_model_property = field[0].full_model_property;
      this.report.metadata.graph.order.order_by_model_property_label = field[0].full_model_property_label ?? field[0].full_model_property;
    }
  }

  onGraphChange(event: any, grouped: boolean, grouped2: boolean = false) {
    if (event.value != null) {
      if (typeof (this.entity) !== "undefined") {
        let field = this.entity.fields.filter(m => m.id == event.value);
        if (field.length > 0) {
          if (grouped) {
            if (!grouped2) {
              this.report.metadata.graph.entity_field_grouped.entity_field_grouped_id = field[0].id;
              this.report.metadata.graph.entity_field_grouped.entity_field_model_property = field[0].full_model_property;
              this.report.metadata.graph.entity_field_grouped.entity_field_model_property_label = field[0].full_model_property_label ?? field[0].full_model_property;
              if (field[0].data_type == "date") {
                this.report.metadata.graph.entity_field_grouped.entity_field_grouped_type = "date";
                this.showDateSelector = true;
              } else {
                this.showDateSelector = false;
              }
            } else {
              this.report.metadata.graph.entity_field_grouped2.entity_field_grouped_id = field[0].id;
              this.report.metadata.graph.entity_field_grouped2.entity_field_model_property = field[0].full_model_property;
              this.report.metadata.graph.entity_field_grouped2.entity_field_model_property_label = field[0].full_model_property_label ?? field[0].full_model_property;
              if (field[0].data_type == "date") {
                this.report.metadata.graph.entity_field_grouped2.entity_field_grouped_type = "date";
              }
            }
          }
          if (!grouped) {
            this.report.metadata.graph.entity_field.entity_field_id = field[0].id;
            this.report.metadata.graph.entity_field.entity_field_model_property = field[0].full_model_property;
            this.report.metadata.graph.entity_field.entity_field_model_property_label = field[0].full_model_property_label ?? field[0].full_model_property;
          }

        }
      }
    } else {
      if (!grouped) {
        this.report.metadata.entity_field = {}
      } else {
        if (grouped2) {
          this.report.metadata.graph.entity_field_grouped2 = {};
        } else {
          this.report.metadata.graph.entity_field_grouped = {};
        }
      }
    }
  };

  clearEntityFieldGrouped(value) {
    if (value == 1) {
      this.report.metadata.graph.entity_field_grouped = {}
    } else {
      this.report.metadata.graph.entity_field_grouped2 = {}
    }

    ;
  }

  // an async function to simulate loading an item from some server
  askValuesToUser(event: any, indexPos: string) {

    //TODO
    let askUser = false;
    this.report.metadata.filters.filtersGroupAND.forEach(element => {
      if (!askUser) {
        let aux = element.filtersGroupOR.filter(m => m.ask_user);
        if (aux.length > 0) askUser = true;
      }
    });

    //SOlo se pregunta si cualquier tab salvo el de permisos.
    if (askUser && indexPos.trim() != "Permisos") {
      this.showInHome = false;
      const ref = this.dialogService.open(ReportsAskUserComponent, {
        data: {
          filters: this.report.metadata.filters,
          entity: this.entity
        },
        header: this.translateService.instant("component.reports.detail.fill_mandatory"),
        width: '60%',
      });
      ref.onClose.subscribe((data: any) => {
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
        if (data) {
          this.report.metadata.filters = data;
          this.save(false, false);
          this.goToEvent(event);
        }
      });
    } else {
      this.goToEvent(event);
      this.showInHome = true;
    }

  }

  errorNoName() {
    this.coreDialogService.notify({
      message: this.translateService.instant("component.reports.detail.name_entity_mandatory"),// 'Por favor, debe indicar el nombre y la entidad del informe',
      header: 'Error',
      icon: 'fas exclamation-circle',
      color: 'var(--orange-500)',
      accept: () => { }
    });
  }

  goToEvent(event: any) {
    this.tabIndexActive = event.index;
    if (event.index == 1) {
      this.onReportTabOpen(this.lastGridEvent);
    } else if (event.index == 2) {
      this.loadGraph();
    } else if (event.index == 3 && this.showMap) {
      this.loadMap();
    }
  }

  onTabOpen(event: any) {
    if (event.index != 0) {
      if (this.report.name != null && this.report.name != "") {
        this.save(false, false); //guardamos por si han tocado algo.
        this.askValuesToUser(event, event.originalEvent.target.innerText);
      } else {
        this.errorNoName();
      }
    }
  }

  onReportTabOpen($event) {
    this.lastGridEvent = $event;
    if (this.tabIndexActive == 1) {
      if (this.report.metadata.report.columns == null || this.report.metadata.report.columns.length == 0) {
        this.messageService.add({ severity: "error", detail: this.translateService.instant("component.reports.detail.no_columns_selected") });
        this.loading = false;
        return;
      }
      if (this.report.id) {
        this.params = {
          _pageSize: $event.rows,
          _page: $event.first,
          _last: $event.last
        };
        this.loading = true;
        //params = Object.assign(params, this.report);

        this.reportsService.loadData(this.report, this.params._page, this.params._pageSize).subscribe({
          next: (data: any) => {
            this.results = data;
            this.rows = this.results.values;
            this.columns = this.report.metadata.report.columns;
            this.totalRows = this.results.totalResults;
            this.resumen_table_records = this.translateService.instant("component.reports.resumen_table_records", { first: $event.first, last: $event.last, totalRecords: this.totalRows });
            this.loading = false;
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.Message });
            this.loading = false;
          }
        });
      }
    }
  }
  orderBy(sortBy) {
    if (this.sortDirection == "asc") { this.sortDirection = "desc"; } else { this.sortDirection = "asc"; }
    if (typeof (this.report.metadata.report.order) === "undefined") this.report.metadata.report.order = { "direction": this.sortDirection, "column_key": null };

    this.report.metadata.report.order.direction = this.sortDirection;
    this.report.metadata.report.order.column_key = sortBy;
    this.save(false, false);
    this.onReportTabOpen(this.lastGridEvent);
  }

  getRowValueExcel(header, value = "") {

    if (typeof (this.entity.fields) !== "undefined") {

      let field = this.entity.fields.find(m => m.id == header.entity_field_id);
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;

      let formatFn = field.formatFn;
      if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
        formatFn = field.configuration.formatFn;
      }
      if (formatFn != null) {
        var format = formatFn.split("|");
        if (format[0] == "date") { return new Date(value); } // this.datePipe.transform(value, "dd/MM/yyyy HH:mm");;
      }

      if (value == null || value == "") {
        value = " ";
      } else {
        //Eliminamos el html
        if (typeof value == "string") {
          value = value.replace(/(<([^>]+)>)/ig, "");
        }
      }
    }
    return value;
  }

  exportExcel(results = this.results) {
    var headers = [];
    var aux = [];
    if (results.values == null || results.values.length == 0) {
      this.messageService.add({ severity: "error", detail: this.translateService.instant("component.reports.detail.no_columns_selected") });
      return;
    }
    //For que recorre cada row y column y devuelve un objeto que será exportado a excel:       
    results.values.forEach(row => {
      var obj = {};
      results.labels.forEach(label => {
        obj[label.column_key] = this.getRowValueExcel(label, row[label.column_key]);
      });
      aux.push(obj);
    });
    results.labels.forEach(label => {
      headers.push(label.description);
    });
    Promise.resolve(null).then(() => this.loading = false);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(aux, { skipHeader: false });
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.book_append_sheet(wb, ws);
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // const worksheet = XLSX.utils.json_to_sheet(aux);
    // const workbook = { Sheets: { 'Resultados': worksheet }, SheetNames: ['Resultados']};
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data");
  }

  exportExcelAllFields() {
    var i = 0;
    var temporal_report_to_export = JSON.parse(JSON.stringify(this.report));
    var entitiesToTable = this.entitiesToTable.filter(e => !e.addAll);

    temporal_report_to_export["metadata"].report.columns = [];

    //if (this.selectedColumns?.entity_name != null) entitiesToTable = entitiesToTable.filter(ef => ef.entity == this.selectedColumns.entity_name);
    entitiesToTable.forEach(entity => {

      temporal_report_to_export["metadata"].report.columns.push(
        {
          order: i,
          entity_field_id: entity.id,
          entity_field_model_property: entity.entity_field_model_property,
          entity_field_model_property_label: entity.entity_field_model_property_label,
          description: /*field.entity_name + "-" +*/ entity.description,
          label: (typeof (entity.label) === "undefined" ? "" : entity.label)
        }
      );
      i++;
    });
    if (this.report.id) {
      var params = {};
      this.loading = true;
      this.reportsService.loadData(temporal_report_to_export, null, null).subscribe(
        data => {
          var results = data;
          this.exportExcel(results);
        },
        error => {
          this.messageService.add({ severity: "error", detail: error.Message });
          this.loading = false;
        }
      );
    }
  };

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  changeFavourite(type) {
    this.usersService.setFavouriteReport(this.report.id, type).subscribe(data => {
      if (type == "data") this.favouriteData = !this.favouriteData;
      if (type == "graph") this.favouriteGraph = !this.favouriteGraph;
      if (type == "map") this.favouriteMap = !this.favouriteMap;
      //this.messageService.add({ closable: false, severity: 'success', detail: "Acción realizada correctamente" });
    },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      })
  }

  graphHome() {
    this.usersService.setGraphHome(this.report.id).subscribe(data => {
      //this.graphForHome = !this.graphForHome;
      //this.messageService.add({ closable: false, severity: 'success', detail: "Acción realizada correctamente" });
    },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      })
  }

  getRowOnlyValue(header, value = "", item, onlyText = false) {

    if (typeof (this.entity.fields) !== "undefined") {

      let field = this.entity.fields.find(m => m.id == header.entity_field_id);
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;

      let formatFn = field.formatFn;
      if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
        formatFn = field.configuration.formatFn;
      }
      if (formatFn != null) {
        var format = formatFn.split("|");
        if (format[0] == "date") value = this.formatDate(value, format);
        if (!onlyText) {
          if (format[0] == "suffix") value = this.formatSuffix(value, format);
          if (format[0] == "phone") value = this.formatPhone(value, format);
          if (format[0] == "mail") value = this.formatMail(value, format);
          if (format[0] == "www") value = this.formatWWW(value, format);
          //if (format[0] == "badge") value = this.formatBadge(field, value, format, item);
          //if (format[0] == "badgeColor") value = this.formatBadgeColor(field, header, value, format);
          if (format[0] == "image") value = this.formatImage(field, format);
          if (format[0] == "currency") value = this.formatCurrency(value, format);
          if (format[0] == "number") value = this.formatNumber(value, format);
        }
      }
      if (String(value) === "false") value = "No";
      if (String(value) === "true") value = "Si";
      if (value == null || value == "") value = " ";
    }
    return value;
  }

  getRowValue(header, value = "", item) {
    return "<span class='p-column-title'>" + header.description + "</span>" + this.getRowOnlyValue(header, value, item, false);
  }

  formatDate(value: any, args: any[]) {
    return this.datePipe.transform(value, args[1]);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatWWW(value: any, args: any[]) {
    if (value == null) return "";
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    var formattedValue = "<a href='" + value + "' target='_blank'>" + value + "</a>";
    return formattedValue;
  }

  formatBadgeColor(field: any, row: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      /*className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";*/

      if (typeof (value) !== "undefined" && value != "" && value != null) {
        className = "p-badge p-component p-badge-no-gutter p-badge-xs  p-mr-2 " + subClassName;
        var formattedValue = "<div class='" + className + "' title='" + value + "'></div>" + value;
      } else {
        var formattedValue = "<div></div>";
      }

    }

    return formattedValue;
  }
  formatBadge(field: any, value: any, args: any[], row: any) {
    //TODO: Falta revisar este.
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.log(e);
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";
    }
    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale).format(value)
    return formattedValue;
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reports.delete_report_message", { name: this.report.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.reportsService.delete(this.report.id).subscribe({
          next: (res: any) => {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("general.confirmation_delete") });
            this._location.back();
            //this.router.navigate(['/reports']);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  openReportClonator() {
    if (!this.create_reports) return;
    const ref = this.dialogService.open(ReportClonatorComponent, {
      data: {
        report_id: this.report.id
      },
      header: "Duplicar informe",
      width: '35%',
      /*baseZIndex: 999*/
    });
    ref.onClose.subscribe((data: any) => {
      this.router.navigateByUrl('/reports/' + data.id);
    })
  }

  //Variables para la pestaña permissions:
  public template: any = {};
  public users: any[] = [];
  public zones: any[] = [];
  public roles: any[] = [];
  public selected_zone: any;
  public selected_zones: any[] = [];
  public selected_user: any;
  public selected_users: any[] = [];
  public selected_role: any;
  public selected_roles: any[] = [];

  //Métodos para la pestaña Permissions:
  save_permissions(message) {
    this.buildMetadata();
    this.reportsService.savePermissions(this.report.id, this.report).subscribe(
      data => {
        this.messageService.add({ closable: false, severity: 'success', detail: message });
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }
  save_zone() {
    //Guarda zona en tabla y lo elimina del dropdown.
    if (this.selected_zone != null) {
      this.zones.splice(this.zones.lastIndexOf(this.selected_zone), 1);
      this.selected_zones.push(this.selected_zone);
      this.selected_zone = null;
      this.save_permissions(this.translateService.instant("component.reports.detail.zone_correct"));
    }
  }
  unlinkZone(zone) {
    //Guarda la zona en dropdown y lo elimina de la tabla.
    var erased = this.selected_zones.splice(this.selected_zones.lastIndexOf(zone), 1);
    this.zones.push(erased[0]);
    this.selected_zone = { name: this.translateService.instant("component.reports.detail.select_zone") };
    this.save_permissions(this.translateService.instant("component.reports.detail.zone_unliked"));
  }
  save_role() {
    if (this.selected_role != null) {
      this.roles.splice(this.roles.lastIndexOf(this.selected_role), 1);
      this.selected_roles.push(this.selected_role);
      this.selected_role = null;
      this.save_permissions(this.translateService.instant("component.reports.detail.rol_correct"));
    }
  }
  unlinkRole(role) {
    var erased = this.selected_roles.splice(this.selected_roles.lastIndexOf(role), 1);
    this.roles.push(erased[0]);
    this.selected_role = { name: this.translateService.instant("component.reports.detail.select_rol") };
    this.save_permissions(this.translateService.instant("component.reports.detail.rol_unliked"));
  }
  save_user() {
    if (this.selected_user != null) {
      this.users.splice(this.users.lastIndexOf(this.selected_user), 1);
      this.selected_users.push(this.selected_user);
      this.selected_user = null;
      this.save_permissions(this.translateService.instant("component.reports.detail.user_correct"));
    }
  }
  unlinkUser(user) {
    var erased = this.selected_users.splice(this.selected_users.lastIndexOf(user), 1);
    this.users.push(erased[0]);
    this.selected_user = { name: this.translateService.instant("component.reports.detail.select_user") };
    this.save_permissions(this.translateService.instant("component.reports.detail.user_unliked"));
  }

  buildMetadata() {
    var finalZones = [];
    var finalUsers = [];
    var finalRoles = [];

    this.selected_zones.forEach(zone => {
      finalZones.push(zone.id);
    });
    this.selected_roles.forEach(role => {
      finalRoles.push(role.id);
    });
    this.selected_users.forEach(user => {
      finalUsers.push(user.id);
    });

    this.report.metadata["permissions"] = {
      zones: finalZones,
      users: finalUsers,
      roles: finalRoles
    }

  }
  loadMetadata() {
    var zonesIds = this.report.metadata["permissions"]["zones"];
    var usersIds = this.report.metadata["permissions"]["users"];
    var rolesIds = this.report.metadata["permissions"]["roles"];

    //this.report.metadata.graph.visualize = "count";

    //Carga la tabla y dropdown de Usuarios:
    this.usersService.combo({}).subscribe(
      data => {
        var tempUsers = [];
        this.users = data.rows;

        this.users.forEach(user => {
          if (usersIds.indexOf(user.id) > -1) {
            this.selected_users.push(user);
          } else {
            tempUsers.push(user);
          }
        });
        this.users = tempUsers;
        /*
        this.selected_users = this.users.filter(function (e) {
          return usersIds.indexOf(e.id) > -1;
        });
        */
      },
      error => {

      }
    );

    //Carga la tabla y dropdown de Zonas:
    if (this.zonesPermission) {

      this.zonesService.combo({}).subscribe(
        data => {
          var tempZones = [];
          this.zones = data.rows;
          this.zones.forEach(zone => {
            if (zonesIds.indexOf(zone.id) > -1) {
              this.selected_zones.push(zone);
            } else {
              tempZones.push(zone);
            }
          });
          /*
          this.selected_zones = this.zones.filter(function (e) {
            return zonesIds.indexOf(e.id) > -1;
          });
          tempZones = this.zones.filter(function (e) {
            return zonesIds.indexOf(e.id) == -1;
          });
          */

          this.zones = tempZones;
        },
        error => {

        }
      );
    }

    //Carga la tabla y dropdown de Roles:
    this.rolesService.combo({}).subscribe(
      data => {
        var tempRoles = [];
        this.roles = data.rows;
        this.roles.forEach(role => {
          if (rolesIds.indexOf(role.id) > -1) {
            this.selected_roles.push(role);
          } else {
            tempRoles.push(role);
          }
        });
        this.roles = tempRoles;

        /*this.selected_roles = this.roles.filter(function (e) {
          return rolesIds.indexOf(e.id) > -1;
        });*/
      }
    )

    /*/Carga la tabla y dropdown de Roles:
    this.rolesService.getComboRoles({ rolesIds: rolesIds }).subscribe(
      data => {
        this.selected_roles = data.filter(function (element) {
          return element.selected == true;
        });
        this.roles = data.filter(function (element) {
          return element.selected != true;
        });
      }
    );*/
  }

  openItem(item: any) {
    let id = item["base_id"];
    let windowFeatures = 'location=0';
    if (this.report.entity.code == "task") {
      //this.router.navigate(['/tasks', 0, id]);
      const url = this.router.serializeUrl(this.router.createUrlTree(['/tasks/', 0, id], { queryParams: {} }));
      window.open(url, '_reportDetailsWindow', windowFeatures);
    } else if (this.report.entity.code == "organization") {
      //Organización
      const url = this.router.serializeUrl(this.router.createUrlTree(['/organizations/', 0, id], { queryParams: {} }));
      window.open(url, '_reportDetailsWindow', windowFeatures);

    } else if (this.report.entity.code == "meeting") {
      //Reunión
      const url = this.router.serializeUrl(this.router.createUrlTree(['/meetings/', id], { queryParams: { meetingId: id, deactivated: true } }));
      window.open(url, '_reportDetailsWindow', windowFeatures);
    } else if (this.report.entity.code == "contact") {
      //Contacto
      const url = this.router.serializeUrl(this.router.createUrlTree(['/contacts/', id], { queryParams: {} }));
      window.open(url, '_reportDetailsWindow', windowFeatures);
    }
    else if (this.report.entity.code == "product") {
      //Producto
      const ref = this.dialogService.open(ProductsDetailComponent, {
        data: {
          id: id
        },
        // header: "Detalles del producto",
        width: '70%',
      });
      ref.onClose.subscribe((data: any) => {
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
        this.onReportTabOpen(this.lastGridEvent);
      })
    } else if (this.report.entity.code == "oportunity" || this.report.entity.code == "sale") {
      //transacciones
      //this.router.navigate(['/transactions', (this.report.entity.code == "oportunity" ? 1 : 2), id]);
      const url = this.router.serializeUrl(this.router.createUrlTree(['/transactions/', (this.report.entity.code == "oportunity" ? 1 : 2), id], { queryParams: {} }));
      window.open(url, '_reportDetailsWindow', windowFeatures);
    } else if (this.report.entity.code == "user") {
      //usuarios
      const ref = this.dialogService.open(UsersDetailComponent, {
        data: {
          id: id
        },
        //header: "Detalles del usuario",
        width: '70%',
      });
      ref.onClose.subscribe((data: any) => {
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
        this.onReportTabOpen(this.lastGridEvent);
      })
    } else if (this.report.entity.code == "family") {
      //familias
      const ref = this.dialogService.open(FamiliesDetailComponent, {
        data: {
          id: id
        },
        //header: "Detalles de la familia",
        width: '40%',
      });
      ref.onClose.subscribe((data: any) => {
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
        this.onReportTabOpen(this.lastGridEvent);
      })
    } else if (this.report.entity.code == "transactionLine") {
      //líneas de transacción
      var transactionLineInfo;
      this.transactionLinesService.getInfo(id).subscribe(
        data => {
          transactionLineInfo = data;
          const url = this.router.serializeUrl(this.router.createUrlTree(['/transactions/', transactionLineInfo.transaction_type_id, transactionLineInfo.transaction_id], { queryParams: {} }));
          window.open(url, '_reportDetailsWindow', windowFeatures);
        },
        error => {
          console.error(error);
        }
      );
    } else {
      if (this.report.entity.name_table == "transactions") {
        this.transactionsService.get(id).subscribe({
          next: (data: any) => {
            //this.router.navigate(['/transactions', data.transaction_type_id, id]);
            const url = this.router.serializeUrl(this.router.createUrlTree(['/transactions/', data.transaction_type_id, id], { queryParams: {} }));
            window.open(url, '_reportDetailsWindow', windowFeatures);
          },
          error: (error: any) => {

          }
        });
      }
    }
  }

  deleteData() {
    if (this.results.values == null || this.results.values.length == 0) {
      this.messageService.add({ severity: "error", detail: this.translateService.instant("component.reports.detail.no_columns_selected") });
      return;
    }
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reports.detail.delete_elements", { totalRows: this.totalRows }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        const ref2 = this.dialogService.open(ReportsRevalidationActionComponent, {
          data: {
            columns: this.report.metadata.report.columns,
            entity: this.entity,
            rowsLength: this.totalRows
          },
          header: this.translateService.instant("component.reports.detail.confirm_user_to_validate"),
          width: '35%',
        });
        ref2.onClose.subscribe((validate: any) => {
          let response = validate;
          if (response) {
            var baseIds = this.rows.map(m => m.base_id);
            this.reportsService.deleteDataMasive(this.report.entity.code, this.report.id).subscribe(
              res => {
                this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("general.confirmation_delete") });
                this.onReportTabOpen(this.lastGridEvent);
              },
              error => {
                this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
              }
            );
          }
        });
      },
      reject: () => {

      }
    });
  }

  updateDataMasive() {
    if (this.results.values == null || this.results.values.length == 0) {
      this.messageService.add({ severity: "error", detail: this.translateService.instant("component.reports.detail.no_columns_selected") });
      return;
    }
    let messageHeader = this.translateService.instant("component.reports.detail.select_field_to_update_multiple", { totalRows: this.totalRows });
    if (this.totalRows == 1) {
      messageHeader = this.translateService.instant("component.reports.detail.select_field_to_update");
    }
    const ref = this.dialogService.open(ReportMasiveUpdateComponent, {
      data: {
        columns: this.report.metadata.report.columns,
        entity: this.entity,
        rowsLength: this.totalRows
      },
      header: messageHeader,
      width: '40%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        const ref2 = this.dialogService.open(ReportsRevalidationActionComponent, {
          data: {
            columns: this.report.metadata.report.columns,
            entity: this.entity,
            rowsLength: this.totalRows
          },
          header: this.translateService.instant("component.reports.detail.confirm_user_to_validate"),
          width: '20%',
        });
        ref2.onClose.subscribe((validate: any) => {
          let response = validate;
          if (response) {

            if (this.report.entity.code == "oportunity" && data.entity_field_model_property == "transactions.transaction_status_id") {
              //Son transacciones de tipo oportunidad pero
              this.reportsService.checkIfExistSaleOrNeedToCreate(false, this.results.baseIds, data.value).subscribe({
                next: (res: any) => {
                  this.auxResponse = res;
                  if (this.auxResponse.exist) {
                    this.coreDialogService.confirm({
                      message: this.auxResponse.message,
                      header: "Confirmar",
                      icon: "pi pi-info-circle",
                      accept: () => {
                        //Son transacciones de tipo oportunidad pero
                        this.reportsService.checkIfExistSaleOrNeedToCreate(true, this.results.baseIds, data.value).subscribe({
                          next: (res: any) => {
                            this.reportsService.updateDataMasive(this.report.entity.code, this.results.baseIds, data).subscribe({
                              next: (res: any) => {
                                this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.field_updated") });
                                this.onReportTabOpen(this.lastGridEvent);
                              },
                              error: (error: any) => {
                                this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                              }
                            });
                            this.onReportTabOpen(this.lastGridEvent);
                          },
                          error: (error: any) => {
                            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                          }
                        });

                      },
                      reject: () => {
                      }
                    })
                  }
                },
                error: (error: any) => {
                  this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                }
              });
            } else {
              this.reportsService.updateDataMasive(this.report.entity.code, this.results.baseIds, data).subscribe({
                next: (res: any) => {
                  this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.field_updated") });
                  this.onReportTabOpen(this.lastGridEvent);
                },
                error: (error: any) => {
                  this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                }
              });
              this.onReportTabOpen(this.lastGridEvent);
            }
            //let datajson = JSON.parse(data);

          }
        });
      }
    });
  }

  createTask() {
    if (this.results.values == null || this.results.values.length == 0) {
      this.messageService.add({ severity: "error", detail: this.translateService.instant("component.reports.detail.no_columns_selected") });
      return;
    }
    var pageSize = this.params._pageSize;
    var totalPages = Math.ceil(this.totalRows / pageSize);
    const ref = this.dialogService.open(ReportCreateTaskComponent, {
      data: {
        reportId: this.report.id,
        reportResults: this.results,
        entity: this.entity,
        page: this.params._page,
        totalPages: totalPages,
        totalRows: this.totalRows,
      },
      header: this.translateService.instant("component.reports.detail.new_task"),
      width: '60%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.onReportTabOpen(this.lastGridEvent);
      }
    });
  }

  mailMarketing() {
    var pageSize = this.params._pageSize;
    var page = (this.params._page / pageSize) + 1;
    var totalPages = Math.ceil(this.totalRows / pageSize);

    if (!this.mail_marketing) return; //Si no tiene el permiso, no hace nada.
    const ref = this.dialogService.open(ReportMailMarketingComponent, {
      data: {
        reportId: this.report.id,
        reportResults: this.results,
        entity: this.entity,
        entity_id: this.entity.id,
        page: this.params._page,
        totalPages: totalPages,
        totalRows: this.totalRows
      },
      header: this.translateService.instant("component.reports.detail.mail_marketing"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.onReportTabOpen(this.lastGridEvent);
      }
    });
  }

  campaigns() {
    if (!this.campaigns_permission) return; //Si no tiene el permiso, no hace nada.
    var pageSize = this.params._pageSize;
    var totalPages = Math.ceil(this.totalRows / pageSize);
    const ref = this.dialogService.open(ReportCampaignComponent, {
      data: {
        reportId: this.report.id,
        reportResults: this.results,
        entity: this.entity,
        entity_id: this.entity.id,
        page: this.params._page,
        totalPages: totalPages,
        totalRows: this.totalRows
      },
      header: this.translateService.instant("component.reports.detail.campaigns"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.onReportTabOpen(this.lastGridEvent);
      }
    });
  }
}
